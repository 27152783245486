import { FulfillmentLot } from '@core/enums/lot.model';

export class ConfirmLot {
  public static readonly type = '[Confirm Lot] Confirm lots';

  constructor(public confirmedLots: FulfillmentLot[]) {}
}

export class ScanLotId {
  public static readonly type = '[Confirm Lot] Scanned lot id';

  constructor(public currentScannedLot: string) {}
}

export class ResetConfirmedLots {
  public static readonly type = '[Confirm Lot] Reset confirmed lots';
}

export class ResetScannedLots {
  public static readonly type = '[Confirm Lot] Reset scanned lots';
}
